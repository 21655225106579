export function objectToFormData(
  obj: Record<string, any>,
  form?: FormData,
  namespace?: string,
): FormData {
  const formData = form || new FormData();

  for (const property in obj) {
    if (!obj.hasOwnProperty(property)) continue;

    const key = namespace ? `${namespace}[${property}]` : property;
    const value = obj[property];

    if (value === null || value === undefined) {
      formData.append(key, '');
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        const arrayKey = `${key}[${index}]`;
        if (item === null || item === undefined) {
          formData.append(arrayKey, '');
        } else if (typeof item === 'object' && !(item instanceof File)) {
          objectToFormData(item, formData, arrayKey);
        } else {
          formData.append(arrayKey, item);
        }
      });
    } else if (typeof value === 'object' && !(value instanceof File)) {
      objectToFormData(value, formData, key);
    } else {
      formData.append(key, value.toString());
    }
  }

  return formData;
}
